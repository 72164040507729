import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useCart } from "~/hooks/useCart"
import { useProcessActions } from "~/hooks/useProcessActions"
import { AddressLocationEntity } from "~/interfaces/entities/AddressLocation"
import { LatLng } from "~/interfaces/entities/LatLng"
import { RootState } from "~/redux/store"
import StoreSearchInputActionIcon from "./../StoreSearchInputActionIcon"
import StoreSuggestions from "./../StoreSuggestions"
import { usePrinticularAutoComplete } from "./hooks/usePrinticularAutoComplete"
import { usePrinticularGeocoder } from "./hooks/usePrinticularGeocoder"

interface Props {}

const StoreSearchInputAWS: React.FC<Props> = () => {
  const process = useProcessActions()
  const { cart, cartActions } = useCart()

  const onPlaceSelect = useCallback(
    async ({ latLng, place }: { latLng: LatLng; place: string }) => {
      cartActions.setStoreLatLng(latLng)
      cartActions.setStorePlace(place)
      process.searchStores({})
    },
    [cartActions, process]
  )

  const selectedPlace = cart.storePlace

  return <SearchInput onSelect={onPlaceSelect} selectedPlace={selectedPlace} />
}

interface SearchInputProp {
  selectedPlace: string
  onSelect: ({ latLng, place }: { latLng: LatLng; place: string }) => void
}

const SearchInput: React.FC<SearchInputProp> = ({
  selectedPlace = "",
  onSelect,
}) => {
  const inputRef = useRef<HTMLInputElement>(null!)
  const isSearchingStore = useSelector<RootState, boolean>(
    state => state.stores.isLoading
  )
  const { t } = useTranslation()
  const [displayValue, setDisplayValue] = useState(selectedPlace)
  const { getLatLng } = usePrinticularGeocoder()
  const { value, setValue, isLoading, data, clearSuggestions } =
    usePrinticularAutoComplete()

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const selectPlace = useCallback(
    async (selected: AddressLocationEntity) => {
      setValue(selected.label, false)
      clearSuggestions()

      if (!selected.latitude) {
        const latLng = await getLatLng(selected.label)
        selected.latitude = latLng.lat
        selected.longitude = latLng.lng
      }

      const latLng: LatLng = {
        lat: selected.latitude,
        lng: selected.longitude,
      }

      onSelect({ latLng, place: selected.label })
    },
    [clearSuggestions, getLatLng, onSelect, setValue]
  )

  // value change this comes from the input field
  useEffect(() => {
    setDisplayValue(value)
  }, [value])

  // selected place change, this comes from redux
  useEffect(() => {
    setDisplayValue(selectedPlace)
  }, [selectedPlace])

  const onDisplayValueClear = useCallback(() => {
    setValue("")
    setDisplayValue("")
    clearSuggestions()
    inputRef.current.focus()
  }, [clearSuggestions, setValue])

  return (
    <InputGroup>
      <Input
        data-testid="p-search-by-address"
        id="p-search-by-address" // GTM: search by address
        ref={inputRef}
        pl={8}
        pr={2}
        borderColor="gray.200"
        name="location"
        placeholder={t(
          "components.store-search.aws.StoreSearchInputAWS.Location"
        )}
        onChange={handleOnChange}
        value={displayValue}
        isDisabled={isSearchingStore}
        autoComplete="off"
        maxLength={200}
      />
      <InputLeftElement
        px={2}
        py={0}
        width="auto"
        fontSize="1.2em"
        children={
          <StoreSearchInputActionIcon
            isLoading={isLoading || isSearchingStore}
            isEmpty={displayValue === ""}
            onClear={onDisplayValueClear}
          />
        }
      />
      {data.length > 0 && (
        <Box
          borderWidth={1}
          borderRadius="lg"
          borderColor="gray.200"
          position="absolute"
          top="45px"
          right={0}
          left={0}
          zIndex={10}
          boxShadow="md"
          data-testid="store-suggestions-list"
        >
          <StoreSuggestions suggestions={data} onSelect={selectPlace} />
        </Box>
      )}
    </InputGroup>
  )
}

export default React.memo(StoreSearchInputAWS)
