import {
  Box,
  Flex,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useAvailableStores } from "~/hooks/useAvailableStores"
import { useCart } from "~/hooks/useCart"
import { useCoupon } from "~/hooks/useCoupon"
import { useSelectedStore } from "~/hooks/useSelectedStore"
import { actions } from "~/redux/process"
import { RootState } from "~/redux/store"
import ScrollView from "../ScrollView"
import StoreList from "./StoreList"
import StoreListEmpty from "./StoreListEmpty"
import StoreSearchInput from "./StoreSearchInput"
import StoreSearchUseMyLocationButton from "./StoreSearchUseMyLocationButton"
import StoreUpdateComfirmationModal from "./StoreUpdateConfirmationModal"

const responsivePR = {
  base: 0,
  md: 4,
}

interface Props {}

const StoreSearchFormNotEmpty: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cartActions } = useCart()
  const { stores: availableStores } = useAvailableStores()
  const { store: selectedStore } = useSelectedStore()
  const { onCouponReset, couponIsValid } = useCoupon({})
  const [lineItemsToKeep, setLineItemsToKeep] = useState<EntityId[]>([])
  const [lineItemsToRemove, setLineItemsToRemove] = useState<EntityId[]>([])
  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()

  const isLoadingStores = useSelector<RootState, boolean>(
    state => state.stores.isLoading
  )

  const onOpen = useCallback(
    (data: { lineItemsToKeep: EntityId[]; lineItemsToRemove: EntityId[] }) => {
      setLineItemsToKeep(data.lineItemsToKeep)
      setLineItemsToRemove(data.lineItemsToRemove)
      openDialog()
    },
    [openDialog]
  )

  const onClose = useCallback(() => {
    closeDialog()
    setLineItemsToKeep([])
    setLineItemsToRemove([])
  }, [closeDialog])

  const onStoreSelect = useCallback(
    (storeId: EntityId) => {
      if (couponIsValid && storeId !== Number(selectedStore?.id)) {
        onCouponReset(
          t(
            "components.store-search.StoreSearchFormNotEmpty.CouponResetStoreReason"
          )
        )
      }

      cartActions.setStore({ storeId, onOpen, onClose })
    },
    [
      cartActions,
      couponIsValid,
      onClose,
      onCouponReset,
      onOpen,
      selectedStore?.id,
      t,
    ]
  )

  const availableStoreCount = availableStores.length
  const shouldShowList = !isLoadingStores && availableStoreCount > 0
  const shouldShowEmptyList = !isLoadingStores && availableStoreCount === 0

  const onDialogDecision = useCallback(
    (choice: boolean) => {
      dispatch(actions.productCompatibilityDialog({ choice }))
    },
    [dispatch]
  )

  return (
    <VStack w="full" height={500} spacing={4}>
      <Box w="full" pr={responsivePR}>
        <Flex
          alignItems={{ base: "center", lg: "flex-start" }}
          justifyContent={{ base: "space-between" }}
          direction={{ base: "column", lg: "row" }}
        >
          <Box>
            <Text fontWeight="">
              {t(
                "components.store-search.StoreSearchFormNotEmpty.SearchInputLabel"
              )}
            </Text>
          </Box>
          <Box mt={{ base: "4", lg: "0" }} mb={{ base: "2", lg: "0" }}>
            <StoreSearchUseMyLocationButton
              display={{ base: "inline-flex", lg: "none" }}
              variant="solid"
            />
            <StoreSearchUseMyLocationButton
              display={{ base: "none", lg: "inline-flex" }}
              variant="link"
            />
          </Box>
        </Flex>
      </Box>
      <Box w="full" pr={responsivePR}>
        <StoreSearchInput />
      </Box>
      <ScrollView data-testid="StoreSearchList" id="StoreSearchList">
        {isLoadingStores && (
          <Flex
            h="full"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" color="primary.500" />
          </Flex>
        )}
        {shouldShowEmptyList && <StoreListEmpty />}
        {shouldShowList && (
          <StoreList
            stores={availableStores}
            selectedId={selectedStore?.id}
            onSelect={onStoreSelect}
          />
        )}
      </ScrollView>

      <StoreUpdateComfirmationModal
        lineItemsToKeep={lineItemsToKeep}
        lineItemsToRemove={lineItemsToRemove}
        onClose={() => onDialogDecision(false)}
        onConfirm={() => onDialogDecision(true)}
        isOpen={isOpen}
      />
    </VStack>
  )
}

export default StoreSearchFormNotEmpty
