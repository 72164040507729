import {
  Button,
  ButtonProps,
  Flex,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiCurrentLocation } from "react-icons/bi"
import { useSelector } from "react-redux"
import InfoModal from "~/components/InfoModal"
import { useCart } from "~/hooks/useCart"
import { useMyGeolocation } from "~/hooks/useMyGeolocation"
import { useProcessActions } from "~/hooks/useProcessActions"
import { LatLng } from "~/interfaces/entities/LatLng"
import { RootState } from "~/redux/store"

interface Props extends ButtonProps {}

const StoreSearchUseMyLocationButtonGoogle: React.FC<Props> = props => {
  const process = useProcessActions()
  const {
    isOpen: isLocationErrorModalOpen,
    onOpen: openLocationErrorModal,
    onClose: closeLocationErrorModal,
  } = useDisclosure()
  const isSearchingStore = useSelector<RootState, boolean>(
    state => state.stores.isLoading
  )
  const [isLoading, setIsLoading] = useState(false)
  const { findMe } = useMyGeolocation()
  const { cartActions } = useCart()
  const { t } = useTranslation()
  const handleFindMe = useCallback(async () => {
    setIsLoading(true)
    const location = await findMe()
    const latLng: LatLng = {
      lat: location?.coords.latitude || 0,
      lng: location?.coords.longitude || 0,
    }
    cartActions.setStoreLatLng(latLng)
    process.searchStores({})
    setIsLoading(false)
  }, [cartActions, findMe, process])

  const leftIcon = isLoading ? (
    <Flex alignItems="center">
      <Spinner size="sm" />
    </Flex>
  ) : (
    <BiCurrentLocation size={20} />
  )

  return (
    <>
      <Button
        data-testid="p-use-location"
        id="p-use-location" // GTM: find location geo lookup
        leftIcon={leftIcon}
        width="full"
        colorScheme="primary"
        color="primary.text"
        onClick={handleFindMe}
        isDisabled={isSearchingStore}
        {...props}
      >
        {t(
          "components.store-search.google.StoreSearchUseMyLocationButtonGoogle.Location"
        )}
      </Button>
      <InfoModal
        title={t(
          "components.store-search.google.StoreSearchUseMyLocationButtonGoogle.LocationNotFound"
        )}
        description={t(
          "components.store-search.google.StoreSearchUseMyLocationButtonGoogle.Description"
        )}
        onClose={closeLocationErrorModal}
        isOpen={isLocationErrorModalOpen}
      />
    </>
  )
}

export default StoreSearchUseMyLocationButtonGoogle
