import { EntityId } from "@jackfruit/common"
import { Box, Heading, Text } from "@chakra-ui/react"
import React from "react"
import Note from "../Note"
import { ScrollElement } from "react-scroll"
import { StoreEntity } from "~/interfaces/entities/Store"
import { getHumanDistance } from "~/services/Utils"

interface Props {
  isSelected: boolean
  store: StoreEntity
  onClick: (id: EntityId) => void
}

const StoreListItem: React.FC<Props> = ({
  store,
  isSelected,
  onClick,
  ...rest
}) => {
  const borderColor = isSelected ? "primary.500" : "transparent"
  const backgroundColor = isSelected ? "primary.50" : "transparent"
  const { parentBindings, ...props } = rest as any
  const distance = getHumanDistance(
    store.distanceToSearchInKms,
    store.countryCode
  )

  return (
    <Box
      {...props}
      onClick={() => onClick(+store.id)}
      transition="all 0.2s ease-in-out"
      _hover={{
        cursor: "pointer",
        boxShadow: "md",
        borderColor: isSelected ? "primary.500" : "primary.200",
      }}
      width="full"
      boxShadow={isSelected ? "md" : ""}
      key={store.id}
      padding={3}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={1}
      borderRadius="md"
      data-selected={`${isSelected}`}
    >
      <Heading as="h6" fontSize="normal">
        {store.name}
      </Heading>
      <Text>{store.address}</Text>
      <Text>
        {store.city} {store.postcode}
      </Text>
      <Note fontStyle="italic">{distance}</Note>
    </Box>
  )
}

export default ScrollElement(StoreListItem)
