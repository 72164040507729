import { Box, Icon, Link, Text, VStack } from "@chakra-ui/react"
import { clamp } from "lodash"
import React, { useState } from "react"
import { FiMapPin } from "react-icons/fi"
import { useKey } from "react-use"
import { AddressLocationEntity } from "~/interfaces/entities/AddressLocation"

interface Props {
  suggestions: AddressLocationEntity[]
  onSelect: (suggestion: AddressLocationEntity) => void
}

const StoreSuggestions: React.FC<Props> = ({ suggestions, onSelect }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectIndex = (index: number) => setSelectedIndex(index)
  const goUp = () => {
    setSelectedIndex(currentIndex =>
      clamp(currentIndex - 1, 0, suggestions.length - 1)
    )
  }
  const goDown = () => {
    setSelectedIndex(currentIndex =>
      clamp(currentIndex + 1, 0, suggestions.length - 1)
    )
  }

  const validateSelection = () => {
    onSelect(suggestions[selectedIndex])
  }

  useKey("ArrowUp", goUp)
  useKey("ArrowDown", goDown)
  useKey("Enter", validateSelection, {}, [selectIndex])

  return (
    <VStack spacing={0} backgroundColor="white" borderRadius="lg">
      {suggestions.map((suggestion, index) => {
        const backgroundColor =
          index === selectedIndex ? "gray.100" : "transparent"
        const { main_text, secondary_text } = suggestion.structured_formatting
        return (
          <Link
            _hover={{}}
            onClick={validateSelection}
            onMouseOver={() => selectIndex(index)}
            key={suggestion.place_id}
            backgroundColor={backgroundColor}
            padding={2}
            data-testid={`store-option-${index}`}
            textDecoration="none"
            borderRadius={0}
            width="full"
            fontSize="sm"
            role="link"
          >
            <Text noOfLines={1}>
              <Icon as={FiMapPin} mr={2} boxSize="1.2em" color="gray.500" />
              <Box as="span" fontWeight="bold" color="gray.700">
                {main_text}
              </Box>
              {secondary_text && (
                <Box as="span" color="gray.500">
                  , {secondary_text}
                </Box>
              )}
            </Text>
          </Link>
        )
      })}
    </VStack>
  )
}

export default StoreSuggestions
